import { NgForm } from '@angular/forms'

export class UIHelpers {
    checkErrors(fc: any, form: any): boolean {
        if(fc.invalid && (fc.touched || fc.dirty || form.submitted)) {
            return true;
        } else {
            return false;
        }
    }

}
