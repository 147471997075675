<nav class="cg">
    <div class="container">
        <div class="logo" [routerLink]="['/']">
            <!-- <img src="/assets/images/logo.png" alt="Logo"> -->
        </div>
        <!-- <ul>
            <li *ngIf="isAdmin">
                <a [routerLink]="['/admin/dashboard']">My Portal</a>
            </li>
            <li *ngIf="isStudent">
                <a [routerLink]="['/student/dashboard']">My Portal</a>
            </li>
            <li *ngIf="isSubAdmin">
                <a [routerLink]="['/sub-admin/dashboard']">My Portal</a>
            </li>
            <li *ngIf="isInstructor">
                <a [routerLink]="['/firm-owner/dashboard']">My Portal</a>
            </li>
            <li *ngIf="!isAuthenticated">
                <a [routerLink]="['/login']">Login</a>
            </li>
        </ul> -->
    </div>
</nav>